import { XCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { NotifierModel } from "types/NotifierModel";

type NotifierProps = NotifierModel & { notifierState: boolean };

const Notifier: React.FC<NotifierProps> = ({
  mode = "success",
  message,
  notifierState,
  link,
  onClick
}) => {
  const modeStyles = {
    success: {
      background: "bg-green-500",
      text: "text-green-600 dark:text-green-500",
      defaultMessage: "Action completed successfully"
    },
    error: {
      background: "bg-red-50",
      text: "text-red-500",
      defaultMessage: "Something went wrong"
    },
    warning: {
      background: "bg-yellow-50",
      text: "text-yellow-400",
      defaultMessage: "Please be cautious"
    }
  };

  // Get the styles and message based on the mode
  const { background, text, defaultMessage } = modeStyles[mode];
  const displayMessage = message || defaultMessage;

  return (
    <p className={`text-[0.85rem] text-left ${text} animate-shake`}>
      {notifierState ? (
        <div className="flex">
          {displayMessage}
          {link && (
            <span>
              &nbsp; - &nbsp;{" "}
              <span
                className="hover:underline text-primary-500 cursor-pointer"
                onClick={onClick}
              >
                {" "}
                {link}
              </span>
            </span>
          )}
        </div>
      ) : (
        <>&nbsp;</>
      )}
    </p>
  );
};

export default Notifier;
