import React from 'react';

interface FormErrorProps {
  message?: string; // Optional to handle cases where the message is undefined
  isSmall?: boolean;
}

const FormError: React.FC<FormErrorProps> = ({ message, isSmall = true }) => {
  if (!message) return <p className={`text-red-500 ${isSmall ? "text-xs" : "text-sm" } mt-[0.35rem]`}>&nbsp;</p>; // Return null instead of an empty string

  return <p className={`text-red-500 ${isSmall ? "text-xs" : "text-sm" } mt-[0.35rem]`}>{message}</p>;
};

export default FormError;
