import React, { FC, useEffect, useRef, useState, Fragment } from "react";
import Logo from "shared/Logo/Logo";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import { useLocation, useNavigate } from "react-router-dom";
import AvatarDropdown from "./AvatarDropdown";
import MenuBar from "shared/MenuBar/MenuBar";
import { StaySearchFormField } from "components/HeroSearchForm/type";
import HeroSearchFormSmall from "components/HeroSearchForm/HeroSearchFormSmall";
import SavedProviders from "shared/SavedProviders/SavedProviders";
import uiUseStore from "store/UIStore";
import appStore from "store/AppStore";
import { useTenantConfigs as UseTenantConfigs } from "utils/tenant";
import SearchTab from "./SearchTab";
import { KEYS } from "constants/KeyConstants";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Services } from "services/service";
import { HomeIcon } from "@heroicons/react/24/outline";
interface StandardHeaderProps {
  className?: string;
}

let WIN_PREV_POSITION = window.pageYOffset;

const StandardHeader: FC<StandardHeaderProps> = ({ className = "" }) => {
  const headerInnerRef = useRef<HTMLDivElement>(null);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const {
    isHomePage,
    setIsShowCareVeticalLine,
    setIsHeaderBorderVisible,
    servicesTag,
    locationvalue,
    HeaderRadius,
    setShowSignup,
    isLoggedIn,
    setIsLoggedIn,
    setShowLogin,
    setIsHomePage
  } = uiUseStore();
  const [logo, setLogo] = useState<string | undefined>();

  const location = useLocation();

  const {
    tenant,
    setTenantConfigs,
    savedProviderList,
    theme,
    profileImage,
    userDetail,
    setUserDetail,
    setProfileImage,
    setCareTypes,
    setSavedProviderList
  } = appStore();

  const navigate = useNavigate();

  useEffect(() => {
    const tenantDetails = UseTenantConfigs(tenant);
    setLogo(tenantDetails.logo.symbol);
    LoadAllCares();
  }, []);

  useEffect(() => {
    setIsHomePage(location.pathname !== "/list");
    setSearchEnabled(location.pathname === "/list");
  }, [location.pathname]);

  useEffect(() => {
    const tenantDetails = UseTenantConfigs(tenant);
    setLogo(tenantDetails.logo.symbol);
    setTenantConfigs(tenantDetails);
  }, [tenant, theme]);

  const LoadAllCares = async () => {
    let result = await Services.LoadCareTypes();
    setCareTypes(result);
  };

  const [showHeroSearch, setShowHeroSearch] =
    useState<StaySearchFormField | null>();

  useOutsideAlerter(headerInnerRef, (event: MouseEvent) => {
    // Check if the clicked target is inside the element with class name 'pac-container'
    if (
      (event.target as HTMLElement).closest(".pac-container") ||
      (event.target as HTMLElement).closest(".drawer-container") ||
      (event.target as HTMLElement).closest(".dialogContainer")
    ) {
      return; // Do nothing if clicked inside 'pac-container'
    }
    setIsShowCareVeticalLine(true);
    setIsHeaderBorderVisible(true);
    // If clicked outside of headerInnerRef and not inside 'pac-container', setShowHeroSearch to null
    setShowHeroSearch(null);
  });

  useEffect(() => {
    fetchUserDetails();
    window.addEventListener("scroll", handleEvent);
    return () => {
      window.removeEventListener("scroll", handleEvent);
    };
  }, []);

  useEffect(() => {
    setShowHeroSearch(null);
  }, [savedProviderList]);

  const handleEvent = () => {
    window.requestAnimationFrame(handleHideSearchForm);
  };

  const fetchUserDetails = async () => {
    const email = localStorage.getItem(KEYS.EMAIL);
    if (email) {
      const result = await Services.GetUserByEmail(email);
      if (result) {
        setUserDetail({
          email: result.email,
          firstName: result.firstName,
          lastName: result.lastName,
          phone: result.phone,
          profilePicture: result.profilePicture ?? ""
        });
        setProfileImage(result.profilePicture ?? "");
      } else {
        localStorage.removeItem(KEYS.ISLOGGEDIN);
      }
    } else {
      localStorage.removeItem(KEYS.ISLOGGEDIN);
    }
  };

  const handleHideSearchForm = () => {
    if (!document.querySelector("#nc-Header-3-anchor")) {
      return;
    }
    //
    let currentScrollPos = window.pageYOffset;
    if (
      WIN_PREV_POSITION - currentScrollPos > 100 ||
      WIN_PREV_POSITION - currentScrollPos < -100
    ) {
      setShowHeroSearch(null);
    } else {
      return;
    }
    WIN_PREV_POSITION = currentScrollPos;
  };

  const logout = () => {
    // setCurrentPage(0);
    setSavedProviderList([]);
    setIsLoggedIn(false);
    localStorage.removeItem(KEYS.ISLOGGEDIN);
    navigate("/");
  };

  //
  const renderHeroSearch = () => {
    return (
      <div
        className={`absolute inset-x-0 top-10 xs:left-5 md:left-0  transition-all will-change-[transform,opacity] ${
          showHeroSearch
            ? "visible"
            : "-translate-x-0 -translate-y-[90px] scale-x-[0.395] scale-y-[0.6] opacity-0 invisible pointer-events-none"
        }`}
      >
        <div className={`w-full max-w-5xl mx-auto pb-6`}>
          <HeroSearchFormSmall />
        </div>
      </div>
    );
  };

  const renderButtonOpenHeroSearch = () => {
    return (
      <div
        className={`w-full relative flex items-center justify-between border border-neutral-200 dark:border-neutral-6000 rounded-full shadow hover:shadow-md transition-all ${
          showHeroSearch
            ? "-translate-x-0 translate-y-20 scale-x-[2.55] scale-y-[2.0] opacity-0 pointer-events-none invisible"
            : "visible"
        }`}
      >
        <div className="flex items-center font-medium xs:text-xs-small md:text-sm">
          <span
            className="block pl-5 pr-4 cursor-pointer xs:py-2 md:py-3 overflow-hidden whitespace-nowrap text-ellipsis"
            onClick={() => setShowHeroSearch("Type of Care")}
          >
            {servicesTag && servicesTag !== "" ? servicesTag : "Type of care"}
          </span>
          <span className="h-5 w-[1px] bg-neutral-300 dark:bg-neutral-700"></span>
          <span
            className="block px-4 cursor-pointer xs:py-2 md:py-3 overflow-hidden whitespace-nowrap text-ellipsis"
            onClick={() => setShowHeroSearch("Zip Code")}
          >
            {locationvalue && locationvalue !== "" ? locationvalue : "Location"}
          </span>
          <span className="h-5 w-[1px] bg-neutral-300 dark:bg-neutral-700"></span>
          <span
            className="block px-4 cursor-pointer xs:py-2 md:py-3 overflow-hidden whitespace-nowrap text-ellipsis"
            onClick={() => setShowHeroSearch("Radius")}
          >
            {HeaderRadius && HeaderRadius !== "" ? HeaderRadius : "Distance"}
          </span>
        </div>
        <div
          className="flex-shrink-0 ml-auto pr-2 cursor-pointer"
          onClick={() => setShowHeroSearch("Type of Care")}
        >
          <span className="md:w-8 md:h-8 xs:w-7 xs:h-7 flex items-center justify-center rounded-full bg-primary-6000  text-white">
            <svg
              className="md:w-5 md:h-5 xs:h-4 xs:w-4"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
              ></path>
            </svg>
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`nc-Header nc-Header-3 fixed z-40 top-0 inset-0 bg-black/30 dark:bg-black/50 transition-opacity will-change-[opacity] xs:hidden md:block ${
          showHeroSearch ? "visible" : "invisible opacity-0 pointer-events-none"
        }`}
      ></div>
      {showHeroSearch && <div id="nc-Header-3-anchor"></div>}
      <header ref={headerInnerRef} className={`sticky top-0 z-40 ${className}`}>
        <div
          className={`bg-white dark:bg-neutral-900 absolute h-full inset-x-0 top-0 transition-transform will-change-[transform,opacity]
          ${showHeroSearch ? "duration-75" : ""} ${
            showHeroSearch ? "md:scale-y-[3.2]" : ""
          }`}
        ></div>
        <div
          className={
            searchEnabled
              ? "relative xs:px-5 md:px-10  py-3 flex"
              : "px-4 lg:container lg:py-2 py-2  lg:px-32 xl:px-56 2xl:px-28 relative flex justify-between items-center"
          }
        >
          <div className="w-full flex items-center justify-between">
            {/* {Logo Element} */}
            <Logo
              img={logo}
              imgLight={logo}
              className={"transition-opacity duration-700 ease-in w-54"}
            />
            {searchEnabled && (
              <div className="ml-72 xs:hidden xl:block">
                <div className="xs:block">{renderButtonOpenHeroSearch()}</div>
                <div className="lg:hidden w-full max-w-lg mx-auto"></div>
                {renderHeroSearch()}
              </div>
            )}
            {/* NAVBAR */}
            <div className="xs:flex relative z-10 items-center justify-end text-neutral-700 dark:text-neutral-100">
              <div className="flex items-center space-x-1">
                {/* {!isLoggedIn && <SwitchDarkMode />} */}

                <SavedProviders className={"block"} />

                {!isHomePage && (
                  <HomeIcon
                    className="w-6 h-6 cursor-pointer"
                    aria-hidden="true"
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                )}

                <ButtonSecondary className="border-0 text-primary-500 xs:hidden sm:block">
                  List your business
                </ButtonSecondary>
                {searchEnabled && (
                  <div className="xs:block xl:hidden ml-1">
                    <SearchTab />
                  </div>
                )}
                {isLoggedIn ? (
                  <div className="flex">
                    <AvatarDropdown
                      imgUrl={`${profileImage}`}
                      logout={logout}
                      username={userDetail.firstName}
                    />
                  </div>
                ) : (
                  <div className="space-x-2 flex ">
                    <ButtonSecondary
                      className="border-gray-200 xs:hidden md:block"
                      onClick={() => setShowLogin(true)}
                    >
                      Login
                    </ButtonSecondary>
                    <ButtonPrimary
                      className=" xs:hidden md:block"
                      onClick={() => setShowSignup(true)}
                    >
                      Sign up free
                    </ButtonPrimary>
                  </div>
                )}
                {!isLoggedIn && (
                  <div
                    className={`${
                      !isHomePage ? "xs:block lg:hidden" : ""
                    } md:hidden`}
                  >
                    <MenuBar />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default StandardHeader;
