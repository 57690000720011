import { Config } from "constants/config";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import x from "images/xtwitter.svg";
const info = [
  {
    title: "Email",
    icon: EnvelopeIcon,
    content: Config.KEY.EMAIL
  }
];

const socialPlatforms = [
  {
    title: "Linkedin",
    icon: "lab la-linkedin-in"
  },
  {
    title: "facebook",
    icon: "lab la-facebook-f"
  },
  {
    title: "Instagram",
    icon: "lab la-instagram"
  },

  {
    title: "twitter",
    icon: "lab la-twitter"
  }
];

const PageContactInfo = () => {
  return (
    <div className="lg:w-1/2 xs:w-full flex md:flex-col xs:flex-col justify-start items-start px-4 space-y-10 xs:pt-0 md:pt-10">
      <p className="text-[#6334e3] font-semibold tracking-[3px] text-[1.3rem]">
        CONTACT US
      </p>
      <p className="xs:text-4xl text-neutral-900 dark:text-neutral-200 lg:text-[3.5rem] font-extrabold leading-[4rem] -ml-[5px]">
        Get in touch
      </p>
      <p className="text-neutral-500 dark:text-neutral-300 text-sm">
        We love questions and feedback - and we're always happy to help!
      </p>
      {info.map((e) => (
        <div className="w-full flex items-center justify-start space-x-6  bg-[#fafafa] dark:bg-neutral-800 px-3 py-3 rounded-xl border-[#ebebeb] dark:border-neutral-700 border-[1px]">
          <div className="p-3 bg-[#ebe6f8] dark:bg-neutral-700 rounded-xl">
            <e.icon className="w-8 h-8 text-[#6334e3] font-bold" />
          </div>
          <div className="w-full flex flex-col items-start justify-start">
            <p className="text-neutral-500 dark:text-neutral-300 text-sm">
              {e.title}
            </p>
            <p className="xs:text-[1rem] lg:text-[1.3rem] font-extrabold tracking-[1px]">
              {e.content}
            </p>
          </div>
        </div>
      ))}

      <div className="w-full flex items-center justify-around space-x-4">
        {socialPlatforms.map((e) => (
          <div
            className="p-3 bg-[#f7f6f6] dark:bg-neutral-800 rounded-xl cursor-pointer"
            title={e.title}
          >
            {e.title == "twitter" ? (
              <img src={x} className="w-8 h-8 text-[#6334e3] font-bold" />
            ) : (
              <i className={`${e.icon} text-[1.9rem]`} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PageContactInfo;
