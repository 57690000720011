import { EnvelopeOpenIcon } from "@heroicons/react/24/outline";
import { KEYS } from "constants/KeyConstants";
import React from "react";
import toast from "react-hot-toast";
import { Services } from "services/service";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import appStore from "store/AppStore";
import uiUseStore from "store/UIStore";
import useCookie from "utils/useCookies";
import { StatusMessages } from "constants/StatusMessages";

interface ConfirmationPopupProps {
  callback: () => void;
}

export const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  callback
}) => {
  const { setShowConfirmationPopup } = uiUseStore();
  const { getCookie } = useCookie();
  const { loading, setLoader } = appStore();

  const email = getCookie(KEYS.EMAIL);

  const onSubmit = async () => {
    if (!email) return;
    try {
      setLoader(true);
      await Services.ForgotPassword(email);
    } finally {
      toast.success(StatusMessages.SuccessMessages.EmailVerification);
      setLoader(false);
    }
  };

  return (
    <div className="flex items-center justify-center my-10">
      <div className="rounded-lg  w-full max-w-md text-center">
        <div className="bg-green-100 text-green-600 p-4 rounded-full mx-auto w-32 h-32 flex items-center justify-center">
          <EnvelopeOpenIcon className="w-16 h-16" />
        </div>

        <h1 className="mt-6 text-xl font-semibold ">Check your email</h1>

        {/* Description */}
        <p className="mt-2 text-sm text-neutral-500">
          Please check the email address{" "}
          <span className="font-medium">
            {email} <br />
          </span>{" "}
          for instructions to reset your password.
        </p>

        {/* Resend Button */}
        <ButtonSecondary
          translate="rounded-lg"
          className="mt-12"
          onClick={onSubmit}
          loading={loading}
        >
          Resend Email
        </ButtonSecondary>
        <div
          className="text-center text-[0.85rem] text-primary-500 hover:underline cursor-pointer mt-4 font-normal"
          onClick={() => {
            callback();
          }}
        >
          Back to login
        </div>
      </div>
      <span className="absolute right-3 top-3">
        <ButtonClose
          sizes="w-6 h-6"
          className="cursor-pointer"
          onClick={() => {
            setShowConfirmationPopup(false);
          }}
        />
      </span>
    </div>
  );
};
