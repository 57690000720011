import { StatusMessages } from "constants/StatusMessages";
import toast from "react-hot-toast";

export interface ImageValidationProps {
    size: number;
    file: File
}

const ImageValidation = (props: ImageValidationProps): boolean => {
    if (!props.file) {
        toast.error(StatusMessages.ErrorMessage.NoImage);
        return false
    }
    let isValid = true;
    const fileExtension = props.file.name.split(".").at(-1)?.toUpperCase();
    const acceptedExtension = ["JPEG", "PNG", "JPG"];
    if (!fileExtension || !acceptedExtension.includes(fileExtension)) {
        toast.error(StatusMessages.ErrorMessage.InvalidImageType)
        isValid = false
    }
    if (props.file.size > props.size) {
        toast.error(StatusMessages.ErrorMessage.ImageSize);
        isValid = false
    }
    return isValid
}

export default ImageValidation