import React, { useEffect } from "react";
import PACRNRoutes from "routers/index";
import Loader from "shared/Loader/Loader";
import appStore from "store/AppStore";
import { getThemeForTenant } from "utils/theme";
import { Toaster, useToasterStore, toast } from "react-hot-toast";

function App() {
  const { tenant } = appStore();
  const TOAST_LIMIT = 1

  const { toasts } = useToasterStore();

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = getThemeForTenant(tenant);
    }
    return () => {
      if ($body) {
        $body.className = "theme-cyan-blueGrey";
      }
    };
  }, [tenant]);

  return (
    <>
      <div className=" text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 ">
        <PACRNRoutes />
        <Toaster toastOptions={{}} containerStyle={{ zIndex: 9999999999 }} />
      </div>
    </>
  );
}

export default App;
