import { KEYS } from "constants/KeyConstants";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import uiUseStore from "store/UIStore";
import useCookie from "utils/useCookies";

const ResetPasswordRedirect: React.FC = () => {
  const navigate = useNavigate();

  const { setIsTokenRecieved } = uiUseStore();
  const { setCookie } = useCookie();

  useEffect(() => {


    // Extract the token from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");

    if (token) {
      setCookie(KEYS.TOKEN, token, 1);
      setIsTokenRecieved(true);
      navigate(`/`);
    } else {
      console.error("No token found in the URL");
      navigate("/invalid-token");
    }
  }, [navigate]);

  return null; // No UI needed for this component
};

export default ResetPasswordRedirect;
