import ButtonPrimary from "shared/Button/ButtonPrimary";
import React, { useEffect } from "react";
import NcImage from "shared/NcImage/NcImage";
import I403Png from "images/403.jpg";

const Page403: React.FC = () => {
  useEffect(() => {
    console.log("403");
  }, []);

  return (
    <div className="nc-Page404">
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
        {/* HEADER */}
        <header className="text-center max-w-2xl mx-auto space-y-2">
          <NcImage src={I403Png} />
          <span className="block text-2xl text-neutral-800 dark:text-neutral-200 tracking-wider font-medium">
            You don't have permission to access this page.
          </span>
          <div className="pt-8">
            <ButtonPrimary href="/">Go to homepage</ButtonPrimary>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Page403;
